<template>
    <div v-bind="$attrs">
        <component
            :is="entity === 'club' && imageUrl ? 'v-img' : 'v-avatar'"
            :size="avatarSize"
            :color="color"
            @mouseenter="mouseEnter"
            @mouseleave="hovering = false"
            @focus="mouseEnter"
            @blur="hovering = false"
        >
            <div class="img-wrapper" :style="`width: ${avatarSize}px; height: ${avatarSize}px`">
                <v-img
                    v-if="imageUrl && useImage && !hovering && !changing"
                    :src="imageUrl"
                    :width="avatarSize"
                    @error="imageFailed"
                />
                <div v-else class="white--text font-weight-bold" :class="textClass">
                    <span v-if="!hovering && !changing">{{ computedInitials }}</span>
                    <avatar-changer
                        v-else-if="canEdit"
                        :entity-id="person.id || 0"
                        :current="person.profile_photo_url"
                        model="athlete"
                        no-submit
                        @input="blob => update(person, blob)"
                        @close="changing = false"
                    >
                        <template #modal-activator="{ open }">
                            <v-btn
                                text
                                icon
                                style="z-index: 2"
                                @click="
                                    () => {
                                        changing = true;
                                        open();
                                    }
                                "
                            >
                                <v-icon :class="textClass" color="white">mdi-camera</v-icon>
                            </v-btn>
                        </template>
                    </avatar-changer>
                </div>

                <div v-if="(hovering || changing) && canEdit" class="img-overlay"></div>
            </div>
        </component>

        <v-snackbar v-model="showVerificationMessage" :timeout="5000" color="primary">
            Your photo has been updated and is awaiting approval.
        </v-snackbar>
    </div>
</template>

<script>
import { VAvatar, VImg } from 'vuetify/lib/components';
import first from 'lodash/first.js';
import last from 'lodash/last.js';
import Athlete from '@/Models/Athlete.js';
import User from '@/Models/User.js';
import Club from '@/Models/Club.js';
import AvatarChanger from '@/Components/AvatarChanger.vue';
import { avatarColor } from '@/helpers';

export default {
    name: 'ScorsyncAvatar',

    components: { AvatarChanger, VAvatar, VImg },

    props: {
        person: {
            type: [Athlete, User, Club, Object],
            required: true,
        },

        size: {
            type: String,
            required: false,
            default: 'sm',
        },

        fontSizeClass: {
            type: String,
            required: false,
            default: null,
        },

        customSize: {
            type: Boolean,
            required: false,
            default: false,
        },

        initials: {
            type: [String, null],
            required: false,
            default: null,
        },

        avatarColor: {
            type: [String, null],
            required: false,
            default: null,
        },

        newAthlete: {
            type: Boolean,
            required: false,
            default: false,
        },

        type: {
            type: String,
            required: false,
            default: '',
        },
    },

    data: () => ({
        useImage: true,
        showChangeButton: true,
        hovering: false,
        changing: false,
        canUpdatePhoto: false,
        showVerificationMessage: false,
    }),

    computed: {
        user() {
            if (!this.$page.props.auth.user) {
                return null;
            }

            return User.query().with('affiliations').find(this.$page.props.auth.user.id);
        },

        entity() {
            if (this.person instanceof Athlete || this.type === 'athlete') {
                return 'athlete';
            }

            if (this.person instanceof User || this.type === 'user') {
                return 'user';
            }

            if (this.person instanceof Club || this.type === 'club') {
                return 'club';
            }

            if (this.newAthlete) {
                return '';
            }

            throw new Error('Invalid person type');
        },

        color() {
            if (this.imageUrl) return null;

            return (
                this.avatarColor ||
                this.person.avatar_color ||
                avatarColor(this.person.first_name, this.person.last_name)
            );
        },

        computedInitials() {
            if (this.initials) {
                return this.initials;
            }

            if (this.person instanceof Athlete) {
                return this.person.initials;
            }

            if (this.person instanceof User) {
                return this.person.initials;
            }

            if (this.person instanceof Club) {
                return this.person.initials;
            }

            if (this.type === 'athlete') {
                return this.person.first_name?.charAt(0) + this.person.last_name?.charAt(0);
            }

            if (this.type === 'club') {
                // Split the club name by spaces and get the first letter of the first word and last word
                const words = this.person.name.split(' ');

                if (words.length > 1) {
                    return first(words).charAt(0).toUpperCase() + last(words).charAt(0).toUpperCase();
                }

                return this.person.name.charAt(0).toUpperCase() + this.person.name.charAt(1).toUpperCase();
            }

            return '';
        },

        textClass() {
            if (this.fontSizeClass) {
                return this.fontSizeClass;
            }

            if (this.customSize) {
                return '';
            }

            return {
                'text-h6': this.size === 'sm' || this.$vuetify.breakpoint.xs,
                'text-h4': this.size === 'md' || this.$vuetify.breakpoint.md,
                'text-h1': ['lg', 'xl', 'xxl'].includes(this.size),
            };
        },

        avatarSize() {
            return {
                sm: 40,
                md: this.$vuetify.breakpoint.md ? 80 : 100,
                lg: this.$vuetify.breakpoint.xs ? 80 : 250,
                xxl: 700,
            }[this.size];
        },

        imageUrl() {
            if (this.newAthlete && this.person.avatar_b64) {
                return this.person.avatar_b64;
            }

            if (this.person?.profile_photo_url) {
                return this.person.profile_photo_url;
            }

            if (this.person?.profile_photo_path) {
                return this.person.profile_photo_path;
            }

            return this.person?.image_url;
        },

        canEdit() {
            return (
                this.newAthlete || this.canUpdatePhoto || (this.user && this.person && this.user.canEdit(this.person))
            );
        },
    },

    created() {
        this.authorizePhotoUpdate();
    },

    methods: {
        mouseEnter() {
            if (this.canEdit) {
                this.hovering = true;
            }
        },

        imageFailed() {
            this.useImage = false;
        },

        async update(person, blob) {
            if (this.newAthlete) {
                this.$emit('update', { person, blob });
            }

            const formData = new FormData();

            formData.append('_method', 'put');

            if (blob) {
                formData.append('avatar', blob, 'avatar.png');
            } else {
                formData.append('force_remove', '1');
            }

            formData.append('model', this.entity);

            let response;

            if (this.person instanceof Athlete) {
                response = await Athlete.api().post(`/api/avatar/${person.id}`, formData);

                if (!this.user.is_admin) {
                    this.showVerificationMessage = true;
                }
            } else if (this.person instanceof Club) {
                response = await Club.api().post(`/api/avatar/${person.id}`, formData);
            } else if (this.person instanceof User) {
                response = await User.api().post(`/api/avatar/${person.id}`, formData);
            }

            this.changing = false;

            return response;
        },

        authorizePhotoUpdate() {
            const params = new URL(window.location).searchParams;
            const authCode = params.get('auth');

            if (authCode) {
                try {
                    const decoded = JSON.parse(atob(authCode));

                    if (this.person instanceof Athlete) {
                        this.canUpdatePhoto = Object.keys(decoded).every(
                            key => decoded[key].toLowerCase() === this.person[key].toLowerCase(),
                        );
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            }
        },
    },
};
</script>

<style scoped>
.night-sky {
    background-image: radial-gradient(
        circle farthest-corner at 12.3% 19.3%,
        rgba(85, 88, 218, 1) 0%,
        rgba(95, 209, 249, 1) 100.2%
    );
}

.sunset-sky {
    background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}

.rainforest {
    background-image: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}

.cosmic-blue {
    background-image: linear-gradient(109.6deg, rgba(156, 252, 248, 1) 11.2%, rgba(110, 123, 251, 1) 91.1%);
}

.cotton-candy-sky {
    background-image: linear-gradient(109.6deg, rgba(253, 199, 141, 1) 11.3%, rgba(249, 143, 253, 1) 100.2%);
}

.cotton-candy {
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
}

.phoenix {
    background-image: radial-gradient(
        circle farthest-corner at 10% 20%,
        rgba(253, 193, 104, 1) 0%,
        rgba(251, 128, 128, 1) 90%
    );
}

.pastel-pink {
    background-image: linear-gradient(109.6deg, rgba(218, 185, 252, 1) 11.2%, rgba(125, 89, 252, 1) 91.1%);
}

.deep-ocean {
    background-image: linear-gradient(109.6deg, rgba(48, 207, 208, 1) 11.2%, rgba(51, 8, 103, 1) 92.5%);
}

.merlot {
    background: linear-gradient(to right, #ad5389, #3c1053);
}

.steel-blue {
    background-image: linear-gradient(to top, #0c3483 0%, #a2b6df 100%, #6b8cce 100%, #a2b6df 100%);
}

.crimson {
    background-image: linear-gradient(64.5deg, rgba(245, 116, 185, 1) 14.7%, rgba(89, 97, 223, 1) 88.7%);
}

.rainbow-sherbet {
    background: #c6ffdd; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f7797d, #fbd786, #c6ffdd); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #f7797d,
        #fbd786,
        #c6ffdd
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.img-wrapper .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
}
</style>

import Model from '@/Models/Model.js';
import Athlete from '@/Models/Athlete.js';
import User from '@/Models/User.js';
import numeral from 'numeral';
import { eventFromProscore } from '@/helpers.js';
import { sentenceCase } from 'change-case';
import AthleteGoalAchievement from '@/Models/AthleteGoalAchievement.js';

export default class AthleteGoal extends Model {
    static entity = 'athlete_goals';

    static modelName = 'AthleteGoal';

    static fields() {
        return {
            id: this.attr(null),

            athlete_id: this.attr(null),
            user_id: this.attr(null),
            event: this.attr(null),
            routine_num: this.attr(null),
            component: this.attr(null),
            value: this.attr(null),
            is_active: this.boolean(true),
            is_private: this.boolean(false),
            auto_deactivate: this.boolean(true),
            achieved_at: this.attr(null),

            athlete: this.belongsTo(Athlete, 'athlete_id'),
            user: this.belongsTo(User, 'user_id'),
            achievements: this.hasMany(AthleteGoalAchievement, 'athlete_goal_id'),

            ...super.fields(),
        };
    }

    get event_short_label() {
        return eventFromProscore(this.event, 'short');
    }

    get event_color() {
        return {
            TI: 'blue',
            DM: 'green',
            TU: 'red',
            TS: 'yellow',
        }[this.event];
    }

    get component_label() {
        return sentenceCase(this.component);
    }

    get value_label() {
        const precision = {
            'single_execution_score': 1,
            'total_execution_score': 1,
            'difficulty': 1,
            'horizontal_displacement': 1,
            'time_of_flight': 3,
            'neutral_deduction': 1,
            'routine_score': ['TI', 'TS'].includes(this.event) ? 3 : 2,
            'total_score': ['TI', 'TS'].includes(this.event) ? 3 : 2,
        }[this.component];

        return numeral(this.value).format(`0.${'0'.repeat(precision)}`);
    }
}

<template>
    <v-autocomplete
        :value="value"
        :items="athletes"
        :loading="isLoading"
        :search-input.sync="search"
        :success="success"
        :error="error"
        hide-no-data
        item-text="name"
        label="Search Athletes"
        placeholder="Start typing to Search"
        prepend-icon="mdi-account-search"
        return-object
        @input="val => $emit('input', val)"
    />
</template>

<script>
import axios from 'axios';

export default {
    name: 'AthleteSelector',

    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({}),
        },

        includeClub: {
            type: Boolean,
            required: false,
            default: false,
        },

        success: {
            type: Boolean,
            required: false,
            default: null,
        },

        error: {
            type: Boolean,
            required: false,
            default: null,
        },
    },

    data: () => ({
        descriptionLimit: 60,
        athletes: [],
        isLoading: false,
        search: null,
    }),

    watch: {
        search: _.debounce(async function search(val) {
            // Items have already been requested
            if (this.isLoading) return;

            this.isLoading = true;

            try {
                const { data } = await axios.get(
                    `/api/athletes/search?query=${val}${this.includeClub ? '&include_club=true' : ''}`,
                );

                this.athletes = data.data.data;
                this.count = this.athletes.length;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }

            this.isLoading = false;
        }, 500),
    },
};
</script>

import VuexORM from '@vuex-orm/core';
import Meet from '@/Models/Meet.js';
import Result from '@/Models/Result.js';
import Session from '@/Models/Session.js';
import Flight from '@/Models/Flight.js';
import StartList from '@/Models/StartList.js';
import ResultCategory from '@/Models/ResultCategory.js';
import TeamDefinitionSetting from '@/Models/TeamDefinitionSetting.js';

import Athlete from '@/Models/Athlete.js';
import AthleteFollow from '@/Models/AthleteFollow.js';
import ClubFollow from '@/Models/ClubFollow.js';
import Club from '@/Models/Club.js';
import AllAroundResult from '@/Models/AllAroundResult.js';
import TrampolineScore from '@/Models/TrampolineScore.js';
import DoubleMiniScore from '@/Models/DoubleMiniScore.js';
import TumblingScore from '@/Models/TumblingScore.js';
import SynchroScore from '@/Models/SynchroScore.js';
import TrampolineSkillDeductions from '@/Models/TrampolineSkillDeductions.js';
import DoubleMiniSkillDeductions from '@/Models/DoubleMiniSkillDeductions.js';
import TumblingSkillDeductions from '@/Models/TumblingSkillDeductions.js';
import SynchroSkillDeductions from '@/Models/SynchroSkillDeductions.js';
import Media from '@/Models/Media.js';
import User from '@/Models/User.js';
import Role from '@/Models/Role.js';
import Affiliation from '@/Models/Affiliation.js';
import TeamQualifier from '@/Models/TeamQualifier.js';
import MeetTeamQualifier from '@/Models/MeetTeamQualifier.js';
import Video from '@/Models/Video.js';
import FlightResultCategory from '@/Models/FlightResultCategory.js';

import TrampolineMobilitySkill from '@/Models/TrampolineMobilitySkill.js';
import TumblingMobilitySkill from '@/Models/TumblingMobilitySkill.js';
import DoubleMiniMobilitySkill from '@/Models/DoubleMiniMobilitySkill.js';
import DoubleMiniSkill from '@/Models/DoubleMiniSkill.js';
import Subscription from '@/Models/Subscription.js';
import Coupon from '@/Models/Coupon.js';
import UploadVideo from '@/Models/UploadVideo.js';
import Country from '@/Models/Country.js';
import Activity from '@/Models/Activity.js';
import ActivityLogItem from '@/Models/ActivityLogItem.js';
import NotificationPreferences from '@/Models/NotificationPreferences.js';
import Job from '@/Models/Job.js';
import JobApplication from '@/Models/JobApplication.js';
import Announcement from '@/Models/Announcement.js';
import AthleteGoal from '@/Models/AthleteGoal.js';
import AthleteGoalAchievement from '@/Models/AthleteGoalAchievement.js';
import DatabaseNotification from '@/Models/DatabaseNotification.js';

const database = new VuexORM.Database();

database.register(User);
database.register(Role);
database.register(Media);
database.register(Meet);
database.register(Result);
database.register(TrampolineScore);
database.register(DoubleMiniScore);
database.register(TumblingScore);
database.register(SynchroScore);
database.register(Athlete);
database.register(AthleteFollow);
database.register(ClubFollow);
database.register(Club);
database.register(AllAroundResult);
database.register(ResultCategory);
database.register(Affiliation);
database.register(Session);
database.register(Flight);
database.register(StartList);
database.register(TeamQualifier);
database.register(MeetTeamQualifier);
database.register(Video);
database.register(FlightResultCategory);
database.register(TrampolineMobilitySkill);
database.register(TumblingMobilitySkill);
database.register(DoubleMiniMobilitySkill);
database.register(DoubleMiniSkill);
database.register(Subscription);
database.register(Coupon);
database.register(TeamDefinitionSetting);
database.register(UploadVideo);
database.register(Country);
database.register(TrampolineSkillDeductions);
database.register(DoubleMiniSkillDeductions);
database.register(TumblingSkillDeductions);
database.register(SynchroSkillDeductions);
database.register(Activity);
database.register(ActivityLogItem);
database.register(NotificationPreferences);
database.register(Job);
database.register(JobApplication);
database.register(Announcement);
database.register(AthleteGoal);
database.register(AthleteGoalAchievement);
database.register(DatabaseNotification);

export default database;

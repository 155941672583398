<template>
    <div>
        <v-dialog v-if="videoDialog" v-model="videoDialog" max-width="1000">
            <v-card v-if="videoData.result && videoData.routine">
                <v-card-title>
                    <!-- two divs spaced left and right -->
                    <div class="d-flex justify-space-between" style="width: 100%">
                        <div class="text-h5">
                            {{ videoTitle }}
                        </div>
                        <div>
                            <v-btn text icon @click="confirmReport = true">
                                <v-icon>mdi-flag</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-card-title>

                <v-card-text>
                    <routine-video v-if="resolvedVideo" :video="resolvedVideo" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <v-btn
                                text
                                class="font-weight-bold"
                                v-on="on"
                                @saved="moveVideo"
                                @click="showMoveVideo = true"
                            >
                                Wrong Athlete?
                            </v-btn>
                        </template>
                        Use this tool to move the video to the correct athlete.
                    </v-tooltip>
                    <v-btn :href="`/videos/${resolvedVideo?.id}/download`" text>
                        <v-icon>mdi-download</v-icon>
                        <template v-if="$vuetify.breakpoint.smAndUp">Download</template>
                    </v-btn>

                    <tooltip-button
                        v-if="videoData.routine.userCanModifyVideo($page.props.auth.user, videoData.result)"
                        :use-tooltip="$vuetify.breakpoint.xsOnly"
                        :tooltip-props="{ top: true }"
                        :button-props="{ text: true, color: 'red darken-1' }"
                        tooltip-text="Remove Video"
                        @click="confirmRemove = true"
                    >
                        <v-icon>mdi-delete</v-icon>
                        <template v-if="$vuetify.breakpoint.smAndUp">Remove</template>
                    </tooltip-button>

                    <v-tooltip v-if="false" top>
                        <template #activator="{ on }">
                            <v-btn
                                v-if="videoData.routine.userCanModifyVideo($page.props.auth.user, videoData.result)"
                                color="red darken-1"
                                text
                                tooltip="Remove Video"
                                v-on="on"
                                @click="confirmRemove = true"
                            >
                                <v-icon>mdi-delete</v-icon>
                                <template v-if="$vuetify.breakpoint.smAndUp">Remove</template>
                            </v-btn>
                        </template>
                        Close Video
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmRemove" max-width="500px">
            <v-card>
                <v-card-title> Remove Video </v-card-title>
                <v-card-text> Are you sure you wish to remove this video? </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="confirmRemove = false"> Cancel </v-btn>
                    <v-btn color="red darken-1" text :loading="removingVideo" @click="removeVideo"> Remove </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmReport" max-width="500px">
            <v-card>
                <v-card-title> Report Video </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="reportReason"
                        type="text"
                        label="What is your reason for reporting this video?"
                        counter
                        maxlength="255"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="confirmReport = false"> Cancel </v-btn>
                    <v-btn color="red darken-1" text :loading="reportingRoutine" @click="report(resolvedVideo)">
                        Report
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showMoveVideo" max-width="500px">
            <move-video
                v-if="resolvedVideo"
                :video="resolvedVideo"
                :saving="movingVideo"
                :meet-id="videoData.meetId || videoData.result.meet_id"
                @saved="moveVideo"
                @close="showMoveVideo = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import { capitalCase } from 'change-case';
import MoveVideo from '@/Components/Modals/MoveVideo.vue';
import RoutineVideo from '@/Components/RoutineVideo.vue';
import Video from '@/Models/Video.js';
import TooltipButton from '@/Components/TooltipButton.vue';

export default {
    name: 'GlobalVideoPlayer',

    components: { TooltipButton, RoutineVideo, MoveVideo },

    data: () => ({
        videoContainer: false,
        confirmRemove: false,
        confirmReport: false,
        showMoveVideo: false,
        errorMessage: '',
        reportReason: '',
        reportingRoutine: false,
        removingVideo: false,
        movingVideo: false,
    }),

    computed: {
        ...mapState('VideoPlayer', ['videoData']),

        videoTitle() {
            return `${this.resolvedAthlete.name} - ${capitalCase(this.videoData.round)} - ${
                this.videoData.resultCategory.event_short_title
            } Routine ${this.videoData.routine.num}`;
        },

        resolvedVideo() {
            if (this.videoData.video) {
                return this.videoData.video;
            }

            return this.videoData.routine.video;
        },

        resolvedAthlete() {
            if (this.videoData.athlete) {
                return this.videoData.athlete;
            }

            return this.videoData.result.athlete;
        },

        videoDialog: {
            get() {
                return this.$store.state.VideoPlayer.videoDialog;
            },

            set(value) {
                if (value) {
                    this.$store.dispatch('VideoPlayer/openVideo');
                } else {
                    this.$store.dispatch('VideoPlayer/closeVideo');
                }
            },
        },
    },

    methods: {
        ...mapActions('VideoPlayer', ['closeVideo']),

        async moveVideo({ athlete, num, round }) {
            this.movingVideo = true;

            try {
                await Video.api().put(`/api/videos/${this.resolvedVideo?.id}/move`, {
                    athlete_id: athlete.id,
                    meet_id: this.meetId || this.videoData.result.meet_id,
                    num,
                    round,
                });

                this.movingVideo = false;
                await this.closeVideo();
                this.showMoveVideo = false;
            } catch (e) {
                console.error(e);
                this.encounteredError = true;
                this.errorMessage = e.response?.data?.message || 'There was a problem moving the video';
                this.movingVideo = false;
            }
        },

        async removeVideo() {
            this.removingVideo = true;

            const video = this.resolvedVideo;

            await Video.api().delete(`/api/videos/${video.id}`);

            await video.$delete();

            this.removingVideo = false;

            this.confirmRemove = false;
            await this.closeVideo();

            this.showDeleteVideoButton = false;
            clearInterval(this.progressChecker);
        },

        async report(video) {
            this.reportingRoutine = true;

            await axios.post('/api/reports', {
                reportable_id: video.id,
                reportable_type: 'App\\Models\\Video',
                reason: this.reportReason,
            });

            this.reportingRoutine = false;

            this.confirmReport = false;
            await this.closeVideo();
        },

        closeVideo() {
            this.$store.dispatch('VideoPlayer/closeVideo');
        },
    },
};
</script>

<style scoped lang="scss"></style>

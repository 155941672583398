<template>
    <v-app id="inspire">
        <Head>
            <slot name="head">
                <title>{{ pageTitle ? pageTitle + ' | Scorsync' : 'Scorsync' }}</title>
                <meta name="description" content="Scorsync delivers fast and accurate results directly to you." />

                <meta name="og:title" content="Scorsync" />
                <meta name="og:description" content="Scorsync delivers fast and accurate results directly to you." />
                <meta property="og:image" :content="`${appUrl}/images/scorsync-cover.png`" />
            </slot>
        </Head>

        <v-navigation-drawer v-if="!hideSideNav" v-model="drawer" width="256" class="blue-grey darken-4" dark app>
            <v-list>
                <v-list-item>
                    <v-list-item-icon>
                        <a :href="$route('app')">
                            <v-avatar size="32">
                                <img src="@/../img/Icon.png" width="100" height="100" alt="Scorsync" />
                            </v-avatar>
                        </a>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            <a :href="$route('app')" style="color: inherit !important">
                                <h3>Scorsync</h3>
                            </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider />

            <v-list nav>
                <v-list-item-group>
                    <v-list-item
                        v-for="navItem in filteredNav"
                        :key="navItem.href"
                        :href="navItem.href"
                        :disabled="navItem.disabled"
                        @click.prevent="visit(navItem)"
                    >
                        <v-list-item-icon>
                            <v-icon :color="navItem.color || 'default'">{{ navItem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="`${navItem.color}--text`">
                                {{ navItem.title }}</v-list-item-title
                            >
                        </v-list-item-content>

                        <v-list-item-icon v-if="navItem.isNew">
                            <v-chip color="blue" small>New</v-chip>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-divider />
        </v-navigation-drawer>

        <v-app-bar v-if="!toggleableAppBar || appBarToggled" app :light="!$vuetify.theme.dark" flat>
            <v-app-bar-nav-icon v-if="!hideSideNav" @click.stop="drawer = !drawer" />

            <v-container class="py-0 px-0 ml-0 fill-height" fluid>
                <div v-if="pageIsLive">
                    <live-indicator color="#E53935" />
                </div>
                <div :style="pageIsLive ? 'max-width: calc(70%)' : 'max-width: calc(80%)'">
                    <h2 class="meet-title" :class="meetTitleSize">
                        <slot name="title">{{ pageTitle }}</slot>
                    </h2>
                </div>

                <div class="ml-auto">
                    <slot name="app-bar-middle">
                        <div v-if="$vuetify.breakpoint.smAndUp">
                            <template v-if="canShowDebugMode">
                                <v-btn icon @click="switchEnvironment">
                                    <v-icon>mdi-home-switch</v-icon>
                                </v-btn>
                                <v-btn v-if="debugMode" icon color="red" @click="debugMode = false">
                                    <v-icon>mdi-test-tube</v-icon>
                                </v-btn>
                                <v-btn v-else icon @click="debugMode = true">
                                    <v-icon>mdi-test-tube</v-icon>
                                </v-btn>
                            </template>

                            <v-btn v-if="theme === 'light'" icon @click="toggleDarkTheme">
                                <v-icon>mdi-white-balance-sunny</v-icon>
                            </v-btn>

                            <v-btn v-else-if="theme === 'dark'" icon @click="toggleDarkTheme">
                                <v-icon>mdi-weather-night</v-icon>
                            </v-btn>

                            <v-btn v-else icon @click="toggleDarkTheme">
                                <v-icon>mdi-theme-light-dark</v-icon>
                            </v-btn>

                            <inertia-link
                                v-if="user && !trimmedToolbar"
                                :href="$route('meets.create')"
                                as="v-btn"
                                elevation="0"
                            >
                                Host a Meet
                            </inertia-link>

                            <inertia-link v-else :href="$route('interest.index')" as="v-btn" elevation="0">
                                Host a Meet
                            </inertia-link>

                            <notification-dropdown v-if="user" />
                        </div>
                    </slot>
                </div>

                <div>
                    <slot name="app-bar-right">
                        <div class="d-flex align-center">
                            <notification-dropdown v-if="user && $vuetify.breakpoint.xsOnly" class="mr-2" />

                            <v-menu v-if="user" offset-y>
                                <template v-if="compressGuestMenu" #activator="{ on, attrs }">
                                    <v-btn text v-bind="attrs" v-on="on" small>
                                        <v-icon small>mdi-account-circle</v-icon>
                                    </v-btn>
                                </template>
                                <template v-else #activator="{ on, attrs }">
                                    <v-btn text v-bind="attrs" v-on="on"> {{ user.name }}</v-btn>
                                </template>
                                <v-list>
                                    <template v-if="user.affiliations.length === 0 && !trimmedToolbar">
                                        <v-list-item
                                            href="/account?tab=tab-athlete"
                                            @click.prevent="$inertia.visit('/account?tab=tab-athlete')"
                                        >
                                            Link Athlete
                                        </v-list-item>

                                        <v-list-item
                                            href="/account?tab=tab-club"
                                            @click.prevent="$inertia.visit('/account?tab=tab-club')"
                                        >
                                            Link Club
                                        </v-list-item>
                                    </template>

                                    <template v-else-if="!trimmedToolbar">
                                        <v-list-item
                                            v-for="affiliation in user.athlete_affiliations"
                                            :key="affiliation.id"
                                            tag="inertia-link"
                                            :to="$route('athletes.show', affiliation.affiliate_id)"
                                        >
                                            <v-list-item-avatar>
                                                <scorsync-avatar :person="affiliation.affiliate" />
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title>{{ affiliation.affiliate.name }}</v-list-item-title>
                                                <v-list-item-subtitle> Athlete </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item
                                            v-for="affiliation in user.club_affiliations"
                                            :key="affiliation.id"
                                            tag="inertia-link"
                                            :to="$route('clubs.show', affiliation.affiliate_id)"
                                        >
                                            <v-list-item-avatar>
                                                <scorsync-avatar :person="affiliation.affiliate" />
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title>{{ affiliation.affiliate.name }}</v-list-item-title>
                                                <v-list-item-subtitle> Club </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>

                                    <v-divider v-if="!trimmedToolbar" />

                                    <v-list-item
                                        v-if="$vuetify.breakpoint.xs && !trimmedToolbar"
                                        href="/interest"
                                        @click.prevent="$inertia.visit('/interest')"
                                    >
                                        <v-list-item-avatar>
                                            <v-icon>mdi-calendar-plus</v-icon>
                                        </v-list-item-avatar>
                                        Host a Meet
                                    </v-list-item>

                                    <v-list-item
                                        v-if="!trimmedToolbar"
                                        href="/account"
                                        @click.prevent="$inertia.visit('/account')"
                                    >
                                        <v-list-item-avatar>
                                            <v-icon>mdi-account</v-icon>
                                        </v-list-item-avatar>
                                        Account
                                    </v-list-item>

                                    <v-list-item
                                        v-if="user?.is_subscribed && !$isIOS && !trimmedToolbar"
                                        href="/billing"
                                    >
                                        <v-list-item-avatar>
                                            <v-icon>mdi-credit-card-multiple</v-icon>
                                        </v-list-item-avatar>
                                        Billing
                                    </v-list-item>
                                    <v-list-item v-else-if="!$isIOS && !trimmedToolbar" href="/subscription/create">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-credit-card-multiple</v-icon>
                                        </v-list-item-avatar>
                                        Subscribe
                                    </v-list-item>

                                    <v-list-item
                                        v-if="!trimmedToolbar"
                                        href="/my-meets"
                                        @click.prevent="$inertia.visit('/my-meets')"
                                    >
                                        <v-list-item-avatar>
                                            <v-icon>mdi-trophy</v-icon>
                                        </v-list-item-avatar>
                                        My Meets
                                    </v-list-item>

                                    <v-list-item v-if="$vuetify.breakpoint.xs" @click.stop="toggleDarkTheme">
                                        <template v-if="theme === 'light'">
                                            <v-list-item-avatar>
                                                <v-icon>mdi-white-balance-sunny</v-icon>
                                            </v-list-item-avatar>
                                            Light Theme
                                        </template>
                                        <template v-else-if="theme === 'dark'">
                                            <v-list-item-avatar>
                                                <v-icon>mdi-weather-night</v-icon>
                                            </v-list-item-avatar>
                                            Dark Theme
                                        </template>
                                        <template v-else>
                                            <v-list-item-avatar>
                                                <v-icon>mdi-theme-light-dark</v-icon>
                                            </v-list-item-avatar>
                                            System Theme
                                        </template>
                                    </v-list-item>

                                    <v-list-item
                                        v-if="user?.is_admin"
                                        href="/import"
                                        @click.prevent="$inertia.visit('/import')"
                                    >
                                        <v-list-item-avatar>
                                            <v-icon>mdi-import</v-icon>
                                        </v-list-item-avatar>
                                        Import
                                    </v-list-item>

                                    <v-list-item v-if="false" :href="testingModeUrl">
                                        <span v-if="$page.props.testing_mode">Disable Testing</span>
                                        <span v-else>Testing Mode</span>
                                    </v-list-item>

                                    <v-list-item v-if="user?.is_admin" href="/athletes/photo-verification">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-account-check</v-icon>
                                        </v-list-item-avatar>
                                        Athlete Photos
                                    </v-list-item>

                                    <v-list-item v-if="user?.is_admin" href="/admin" target="_blank">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-shield-account</v-icon>
                                        </v-list-item-avatar>
                                        Admin
                                    </v-list-item>
                                    <v-list-item v-if="user?.is_admin" href="/horizon">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-chart-line</v-icon>
                                        </v-list-item-avatar>
                                        Horizon
                                    </v-list-item>
                                    <v-list-item v-if="user?.is_admin" href="/pulse">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-heart-pulse</v-icon>
                                        </v-list-item-avatar>
                                        Pulse
                                    </v-list-item>

                                    <v-list-item v-if="!trimmedToolbar" href="/logs">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-file-document-outline</v-icon>
                                        </v-list-item-avatar>
                                        Logs
                                    </v-list-item>

                                    <v-divider v-if="!trimmedToolbar" />

                                    <v-list-item href="#" @click.prevent="logout">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-logout</v-icon>
                                        </v-list-item-avatar>
                                        Logout
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <div v-else-if="!user && compressGuestMenu">
                                <v-menu offset-y>
                                    <template #activator="{ on, attrs }">
                                        <v-btn text v-bind="attrs" v-on="on">
                                            <v-icon>mdi-account-circle-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-if="$page.props.currentRouteName !== 'login'"
                                            :href="`/login?redirect=${redirect}`"
                                        >
                                            Login
                                        </v-list-item>

                                        <v-list-item
                                            v-if="$page.props.currentRouteName !== 'register'"
                                            href="/register"
                                        >
                                            Register
                                        </v-list-item>

                                        <v-list-item v-if="$vuetify.breakpoint.xs" @click.stop="toggleDarkTheme">
                                            <span v-if="theme === 'light'" class="font-weight-bold">
                                                <v-icon v-if="false">mdi-white-balance-sunny</v-icon>
                                                Light Theme
                                            </span>
                                            <span v-else-if="theme === 'dark'" class="font-weight-bold">
                                                <v-icon v-if="false">mdi-weather-night</v-icon>
                                                Dark Theme
                                            </span>
                                            <span v-else>
                                                <v-icon v-if="false" class="font-weight-bold"
                                                    >mdi-theme-light-dark</v-icon
                                                >
                                                System Theme
                                            </span>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <div v-else>
                                <v-btn
                                    v-if="$page.props.currentRouteName !== 'login'"
                                    :href="`/login?redirect=${redirect}`"
                                    elevation="0"
                                    @click.prevent="$inertia.visit(`/login?redirect=${redirect}`)"
                                >
                                    Login
                                </v-btn>

                                <v-btn
                                    v-if="$page.props.currentRouteName !== 'register'"
                                    href="/register"
                                    elevation="0"
                                    @click.prevent="$inertia.visit('/register')"
                                >
                                    Register
                                </v-btn>
                            </div>
                        </div>
                    </slot>
                </div>
            </v-container>
        </v-app-bar>

        <v-main :class="{ 'grey lighten-3': !$vuetify.theme.dark }">
            <v-banner :value="$page.props.testing_mode" color="orange darken-2" dark class="text-center">
                <strong>
                    Testing Mode. The results shown are for testing only.
                    <a :href="testingModeUrl">Back to live results</a>.
                </strong>
            </v-banner>

            <v-container fluid>
                <v-row>
                    <v-col class="px-0">
                        <v-alert
                            v-if="$page.props.app_environment === 'staging'"
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                            dismissible
                            icon="mdi-test-tube"
                        >
                            <strong>Testing</strong>
                            <p>You are on the staging server. This server is meant for testing only.</p>

                            <p><a href="https://scorsync.com/app">Live site</a></p>
                        </v-alert>

                        <v-alert
                            v-for="announcement in announcements"
                            :key="announcement.id"
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                            dismissible
                        >
                            <template #close>
                                <v-btn icon color="info" @click="forget(announcement)">
                                    <v-icon>cancel</v-icon>
                                </v-btn>
                            </template>
                            <strong>{{ announcement.title }}</strong>
                            <p v-html="announcement.description"></p>
                        </v-alert>

                        <v-alert
                            v-if="!trampShoeAlertCleared"
                            elevation="2"
                            prominent
                            icon="mdi-shoe-print"
                            dismissable
                            class="mx-3"
                        >
                            <template #close>
                                <v-btn icon color="white" @click="forgetTrampShoeAlert">
                                    <v-icon>cancel</v-icon>
                                </v-btn>
                            </template>
                            <strong>Tramp Shoes for Sale!</strong>
                            <p>
                                <span>
                                    Tramp shoes are available for purchase in the Scorsync store. Get yours today!
                                </span>
                            </p>

                            <v-btn href="https://scorsync-store.square.site" target="_blank" color="info"
                                >Shop Now</v-btn
                            >
                        </v-alert>

                        <v-alert
                            v-if="showAssociationReminder"
                            elevation="2"
                            prominent
                            icon="mdi-reminder"
                            dismissable
                            class="mx-3"
                        >
                            <template #close>
                                <v-btn icon color="white" @click="forgetAssociationReminder">
                                    <v-icon>cancel</v-icon>
                                </v-btn>
                            </template>
                            <strong>Reminder</strong>
                            <p>
                                <span>
                                    It looks like you have not associated your account with an athlete profile or club.
                                    Enjoy all of the benefits of Scorsync by
                                </span>
                                <inertia-link
                                    href="/account"
                                    class="text-decoration-underline"
                                    :class="{ 'white--text': $vuetify.theme.dark }"
                                >
                                    linking your account </inertia-link
                                >.
                            </p>
                        </v-alert>

                        <slot></slot>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <slot name="footer">
            <v-footer color="blue-grey darken-4" app inset absolute class="pl-0">
                <v-row justify="center" no-gutters>
                    <v-col class="py-4 text-center white--text" cols="12">
                        &copy; {{ new Date().getFullYear() }} <strong>Scorsync</strong>. Created by Austin White.
                        <span v-if="false">Version {{ $page.props.app_version }}.</span>
                        <br />
                        <a
                            href="mailto:support@scorsync.com"
                            class="white--text"
                            @click.prevent="cancelSubscriptionDialog = true"
                        >
                            Contact Support
                        </a>
                        <inertia-link href="/terms-of-service" class="white--text ml-4">Terms of Service</inertia-link>
                        <inertia-link href="/privacy-policy" class="white--text ml-4">Privacy Policy</inertia-link>
                        <a href="#" class="white--text ml-4" @click.prevent="manageCookies">Cookie Settings</a>
                        <inertia-link href="/change-log" class="white--text ml-4">Recent Updates</inertia-link>
                        <a
                            v-if="user"
                            href="#"
                            class="white--text ml-4"
                            @click.prevent="confirmingAccountDeletion = true"
                        >
                            Remove my account
                        </a>
                    </v-col>
                </v-row>
            </v-footer>
        </slot>

        <div id="logged-in-user-id" style="display: none">{{ $page.props.auth.user?.id }}</div>

        <!-- Dialog for Cancel Subscription Instructions -->
        <v-dialog v-model="cancelSubscriptionDialog" max-width="500">
            <v-card>
                <v-card-title class="text-h5">Contact Support</v-card-title>
                <v-card-text>
                    <p>Are you trying to cancel your subscription? If so, please follow these steps:</p>
                    <ol>
                        <li>Go to the <a href="/billing">billing</a> page to manage your subscription.</li>
                        <li>If you still need further assistance, feel free to reach out to support.</li>
                    </ol>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" href="/billing">Go to Billing Page</v-btn>
                    <v-btn color="secondary" href="mailto:support@scorsync.com">Contact Support</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="cancelSubscriptionDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmingAccountDeletion" max-width="600">
            <v-card>
                <v-card-title class="text-h5"> Are you sure you wish to delete your account? </v-card-title>

                <v-card-text>
                    Scorsync takes your privacy seriously and offers the ability to remove your account along with all
                    of your personal information associated with it. It is important that you understand that this
                    action is irreversible and that your active subscription, if you have one, will be immediately
                    canceled without refund. Please click to confirm this action or reach out to
                    <a href="mailto:support@scorsync.com">support@scorsync.com</a> if you have any questions that you
                    wish to discuss prior to account deletion.
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn color="red darken-1" text @click="deleteAccount">
                        <v-icon>mdi-delete</v-icon>
                        Delete Account
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="showSuccess" color="green" timeout="-1" top>
            {{ $page.props.flash.success }}

            <template #action="{ attrs }">
                <v-btn text v-bind="attrs" @click="showSuccess = false">Close</v-btn>
            </template>
        </v-snackbar>

        <v-snackbar v-model="showError" timeout="5000" bottom>
            {{ errorMessage }}

            <template #action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="showError = false">Close</v-btn>
            </template>
        </v-snackbar>

        <v-snackbar v-model="showMessage" :color="message?.color || 'success'" timeout="10000" top>
            {{ message?.message }}

            <template #action="{ attrs }">
                <v-btn text v-bind="attrs" @click="showMessage = false">Close</v-btn>
            </template>
        </v-snackbar>

        <global-video-player v-if="$store.state.VideoPlayer.videoDialog" />
    </v-app>
</template>

<script>
import get from 'lodash/get.js';
import { VBtn } from 'vuetify/lib';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/vue';
import LiveIndicator from '@/Components/LiveIndicator.vue';
import Meet from '@/Models/Meet.js';
import User from '@/Models/User.js';
import GlobalVideoPlayer from '@/Components/GlobalVideoPlayer.vue';
import ScorsyncAvatar from '@/Components/ScorsyncAvatar.vue';
import NotificationDropdown from '@/Components/NotificationDropdown.vue';

const appUrl = import.meta.env.VITE_APP_URL;

export default {
    components: {
        NotificationDropdown,
        ScorsyncAvatar,
        GlobalVideoPlayer,
        LiveIndicator,
        VBtn,
    },

    data: () => ({
        showingNavigationDropdown: false,
        menu: [],
        filterMenu: null,
        drawer: null,
        forgottenAnnouncements: null,
        reminderCleared: false,
        trampShoeAlertCleared: false,
        themeListener: null,
        mediaListener: null,
        theme: null,
        appUrl,
        showSuccess: false,
        showError: false,
        errorMessage: null,
        showMessage: false,
        message: null,
        appBarToggled: false,
        cancelSubscriptionDialog: false,
        confirmingAccountDeletion: false,

        nav: [
            {
                icon: 'home',
                title: 'Home',
                href: '/app',
            },

            {
                icon: 'emoji_events',
                title: 'Meets',
                href: '/meets',
            },

            {
                icon: 'person',
                title: 'Athletes',
                href: '/athletes',
            },
            {
                icon: 'people',
                title: 'Clubs',
                href: '/clubs',
            },
            {
                icon: 'mdi-shoe-print',
                title: 'Store',
                href: 'https://scorsync-store.square.site',
                color: 'blue',
                isNew: true,
            },
            {
                icon: 'mdi-briefcase-search',
                title: 'Jobs',
                href: '/jobs',
            },
            {
                icon: 'mdi-ticket-percent',
                title: 'Coupons',
                href: '/coupons',
                validation: (user) => user && (user.is_admin || [698, 700].includes(user.id)),
            },
            {
                icon: 'mdi-file-document-edit',
                title: 'Submit Mobility',
                href: '/mobility-submissions/create',
                validation: (user) => user && user.hasPermissionTo('submit mobility'),
            },
            {
                icon: 'mdi-pen',
                title: 'Mobility Review',
                href: '/mobility-submissions',
                validation: (user) => user && user.hasPermissionTo('review mobility'),
            },
            {
                icon: 'mdi-check-circle',
                title: 'Qualifications',
                href: '/qualifications',
            },
            {
                icon: 'mdi-web',
                title: 'Selection Rankings',
                href: '/rankings',
            },
            {
                icon: 'mdi-podium-gold',
                title: 'Leaderboard',
                href: '/leaderboard',
                validation: (user) => user?.is_admin,
            },
            {
                icon: 'mdi-hammer',
                title: 'Tools',
                href: '/tools',
            },
        ],
    }),

    computed: {
        user() {
            return User.query().withAllRecursive().find(this.$page.props.auth.user?.id);
        },

        pageTitle() {
            return this.$page.props.layoutOptions?.pageTitle;
        },

        hideSideNav() {
            return this.$page.props.layoutOptions?.hideSideNav && this.$isIOS;
        },

        trimmedToolbar() {
            return this.$page.props.layoutOptions?.trimmedToolbar;
        },

        hideAssociationReminder() {
            return this.$page.props.layoutOptions?.hideAssociationReminder;
        },

        hideLiveStatus() {
            return this.$page.props.layoutOptions?.hideLiveStatus;
        },

        toggleableAppBar() {
            return this.$page.props.layoutOptions?.toggleableAppBar && this.$vuetify.breakpoint.smAndDown;
        },

        startCollapsed() {
            return this.$page.props.layoutOptions?.startCollapsed || this.$vuetify.breakpoint.mdAndDown;
        },

        path() {
            return window.location.pathname;
        },

        redirect() {
            return window.location.pathname + window.location.search;
        },

        meetTitleSize() {
            if (!this.pageTitle) {
                return '';
            }

            if (this.pageTitle.length < 15) {
                return 'meet-title-lg';
            }

            if (this.pageTitle.length < 25) {
                return 'meet-title-md';
            }

            return 'meet-title-sm';
        },

        filteredMenu() {
            if (!this.filterMenu) {
                return this.menu.items;
            }

            return this.menu.items.filter((item) => item.title.startsWith(this.filterMenu));
        },

        activeMenuItem() {
            if (!this.menu) {
                return null;
            }

            return null;
        },

        compressGuestMenu() {
            return this.$vuetify.breakpoint.xs;
        },

        pageIsLive() {
            if (this.hideLiveStatus) {
                return false;
            }

            const meetId = this.$page.props.meet_id || this.$page.props.meetId || this.$page.props.meet?.id;

            if (!meetId) {
                return false;
            }

            const meet = Meet.find(meetId);

            if (!meet) {
                return false;
            }

            return (
                this.$moment().isSameOrAfter(meet.timestamp('start_date')) &&
                this.$moment().isSameOrBefore(meet.timestamp('end_date'))
            );
        },

        announcements() {
            const announcements = this.$page.props.announcements || [];

            _.remove(announcements, (announcement) => this.forgottenAnnouncements.includes(announcement.id));

            return announcements;
        },

        filteredNav() {
            return this.nav.filter((navItem) => {
                if (navItem.validation) {
                    return navItem.validation(this.user);
                }

                return true;
            });
        },

        testingModeUrl() {
            const url = new URL(window.location.href);

            if (this.$page.props.testingMode) {
                url.searchParams.set('testing_mode', 0);
            } else {
                url.searchParams.set('testing_mode', 1);
            }

            return url.toString();
        },

        showAssociationReminder() {
            return (
                !this.hideAssociationReminder &&
                this.$page.props.currentRouteName !== 'account.index' &&
                this.user &&
                this.user.affiliations.length === 0 &&
                !this.reminderCleared
            );
        },

        canShowDebugMode() {
            return (
                this.user &&
                (this.user.is_admin ||
                    (this.user.roles && this.user.roles.map((role) => role.name).includes('Videographer')))
            );
        },

        debugMode: {
            get() {
                return this.$store.state.debugMode;
            },

            set(value) {
                this.$store.commit('SET_DEBUG_MODE', value);
            },
        },
    },

    watch: {
        startCollapsed(value) {
            if (value) {
                this.drawer = !value;
            }
        },

        '$page.props.auth.user': {
            handler() {
                if (this.$page.props.auth.user) {
                    User.insert({ data: this.$page.props.auth.user });
                }
            },
            deep: true,
        },
    },

    created() {
        if (this.$page.props.menu) {
            this.menu = this.$page.props.menu;
        }

        if (this.$page.props.auth.user) {
            User.insert({ data: this.$page.props.auth.user });
        }

        if (this.user) {
            Sentry.setUser(this.user.$toJson());

            this.theme = this.user.theme_preference;
        }

        this.message = this.$page.props.globalMessage;

        if (this.message) {
            this.showMessage = true;
        }

        if (this.$page.props.flash.success) {
            this.showSuccess = true;
        }

        this.forgottenAnnouncements = JSON.parse(localStorage.getItem('forgotten-announcements')) || [];
        this.reminderCleared = localStorage.getItem('affiliation-reminder-cleared') === 'true';
        this.trampShoeAlertCleared = localStorage.getItem('tramp-shoe-alert-cleared') === 'true';

        if (this.startCollapsed !== null) {
            this.drawer = !this.startCollapsed;
        }

        this.$echo.channel('app').listen('NewRelease', () => {
            this.$store.commit('SET_FORCE_HARD_NAVIGATION', true);
            console.log('New release detected. Forcing hard navigation.');
        });

        if (this.$page.props.verifiable_meet_ids) {
            this.$store.commit('SET_VERIFIABLE_MEET_IDS', this.$page.props.verifiable_meet_ids);
        }
    },

    mounted() {
        this.loadAnalytics();

        this.theme = this.$page.props.themePreference || this.user?.theme_preference || Cookies.get('theme_preference');

        this.mediaListener = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

        this.themeListener = (e) => {
            if (this.theme === 'system') {
                this.$vuetify.theme.dark = !!e.matches;
            }
        };

        this.setTheme();
    },

    methods: {
        get,

        logout() {
            axios.post('/logout').then(() => {
                window.location = '/';
            });
        },

        visit(navItem) {
            if (navItem.href.includes('http')) {
                window.location.href = navItem.href;
            } else {
                this.$inertia.visit(navItem.href);

                // Hide the navigation drawer if it was previously clicked to open
                // Otherwise, leave it open
                if (this.drawer && this.$vuetify.breakpoint.mdAndDown) {
                    this.drawer = false;
                }
            }
        },

        forget(announcement) {
            if (!this.forgottenAnnouncements) {
                this.forgottenAnnouncements = [];
            }

            this.forgottenAnnouncements.push(announcement.id);

            localStorage.setItem('forgotten-announcements', JSON.stringify(this.forgottenAnnouncements));
        },

        async toggleDarkTheme() {
            const next = {
                system: 'light',
                light: 'dark',
                dark: 'system',
            };

            if (!this.theme) {
                this.theme = 'system';
            } else {
                this.theme = next[this.theme];
            }

            this.setTheme();

            try {
                if (this.user) {
                    await axios.put('/api/theme-preference', { theme_preference: this.theme }, { errorHandle: false });
                }
            } catch (e) {
                if (e.response) {
                    this.errorMessage = e.response.data.message;
                } else {
                    this.errorMessage = 'An unknown error occurred.';
                }

                this.showError = true;
            }
        },

        setTheme() {
            if (this.theme === 'system') {
                this.setThemeSystem();
            } else if (this.theme === 'light') {
                this.setThemeLight();
            } else {
                this.setThemeDark();
            }
        },

        setThemeLight() {
            this.$vuetify.theme.dark = false;
            Cookies.set('theme_preference', 'light');

            if (this.mediaListener) {
                this.mediaListener.removeEventListener('change', this.themeListener);
            }
        },

        setThemeDark() {
            this.$vuetify.theme.dark = true;
            Cookies.set('theme_preference', 'dark');

            if (this.mediaListener) {
                this.mediaListener.removeEventListener('change', this.themeListener);
            }
        },

        setThemeSystem() {
            if (this.mediaListener) {
                if (this.mediaListener.matches) {
                    this.$vuetify.theme.dark = true;
                } else {
                    this.$vuetify.theme.dark = false;
                }

                this.mediaListener.addEventListener('change', this.themeListener);
            } else {
                this.$vuetify.theme.dark = false;
            }

            Cookies.set('theme_preference', 'system');
        },

        switchEnvironment() {
            if (this.$page.props.app_environment === 'production') {
                window.location.href = window.location.href.replace('scorsync.com', 'scorsync-online.test');
            } else {
                window.location.href = window.location.href.replace('scorsync-online.test', 'scorsync.com');
            }
        },

        forgetTrampShoeAlert() {
            this.trampShoeAlertCleared = true;
            localStorage.setItem('tramp-shoe-alert-cleared', 'true');
        },

        forgetAssociationReminder() {
            this.reminderCleared = true;
            localStorage.setItem('affiliation-reminder-cleared', 'true');
        },

        manageCookies() {
            this.$CC.show(true);
        },

        loadAnalytics() {
            const userPreferences = this.$CC.getUserPreferences();

            if (userPreferences.acceptedCategories.includes('analytics')) {
                this.$CC.loadScript('https://www.googletagmanager.com/gtag/js?id=G-PZ0FV2MQ55').then(() => {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                        window.dataLayer.push(arguments);
                    }
                    gtag('js', new Date());

                    gtag('config', 'G-PZ0FV2MQ55', { anonymize_ip: true });
                });
            }
        },

        deleteAccount() {
            if (!this.user) {
                return;
            }

            window.location.href = `/user/${this.user.id}/delete`;

            this.confirmingAccountDeletion = false;
        },
    },
};
</script>

<style lang="scss">
@import '../../scss/variables.scss';

.v-application {
    font-family: $body-font-family !important;

    [class*='text-'] {
        font-family: $body-font-family !important;
    }

    a {
        color: #455a64;
        font-weight: bold;
    }
}

.meet-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}

.meet-title-sm {
    font-size: 0.9rem;
}

.meet-title-md {
    font-size: 1rem;
}

.meet-title-lg {
    font-size: 1.5rem;
}
</style>

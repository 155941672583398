export default {
    data: () => ({
        loadedScripts: {},
        loadedStylesheets: {},
    }),

    methods: {
        loadScript(url, id, callback) {
            if (document.getElementById(id)) {
                if (callback) {
                    callback();
                }
            } else {
                const script = document.createElement('script');
                script.id = id;
                script.setAttribute('src', url);
                script.setAttribute('type', 'text/javascript');
                document.getElementsByTagName('head')[0].appendChild(script);

                script.onload = () => {
                    if (callback) {
                        callback();
                    }
                };

                this.loadedScripts[id] = true;
            }
        },

        loadStylesheet(url, id) {
            if (!document.getElementById(id)) {
                const stylesheet = document.createElement('link');
                stylesheet.id = id;
                stylesheet.setAttribute('href', url);
                stylesheet.setAttribute('type', 'text/css');
                stylesheet.setAttribute('rel', 'stylesheet');
                document.getElementsByTagName('head')[0].appendChild(stylesheet);

                this.loadedStylesheets[id] = true;
            }
        },
    },

    beforeUnmount() {
        Object.keys(this.loadedScripts).forEach(id => {
            const script = document.getElementById(id);

            if (script) {
                script.remove();
            }
        });

        Object.keys(this.loadedStylesheets).forEach(id => {
            const stylesheet = document.getElementById(id);

            if (stylesheet) {
                stylesheet.remove();
            }
        });
    },
};

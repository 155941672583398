<template>
    <div style="display: inline-block">
        <v-menu v-model="menuOpen" offset-y :close-on-content-click="false">
            <template #activator="{ on, attrs }">
                <div style="display: inline-block">
                    <v-badge v-if="unreadCount > 0" :content="unreadCount" overlap>
                        <v-btn text v-bind="attrs" v-on="on" :small="$vuetify.breakpoint.xsOnly">
                            <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-bell</v-icon>
                        </v-btn>
                    </v-badge>

                    <v-btn v-else v-bind="attrs" v-on="on" text :small="$vuetify.breakpoint.xsOnly">
                        <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-bell</v-icon>
                    </v-btn>
                </div>
            </template>

            <!-- Dropdown content -->
            <v-card style="max-width: 500px">
                <v-container>
                    <v-alert v-if="errorMarkingNotification" color="error" dismissible>
                        Error marking notification as read
                    </v-alert>

                    <v-row>
                        <v-col>
                            <v-select
                                v-model="viewing"
                                :items="['All', 'Unread', 'Read']"
                                dense
                                outlined
                                hide-details
                            />
                        </v-col>
                        <v-spacer />
                        <v-col class="text-right">
                            <v-btn
                                :disabled="markingAll || unreadNotifications.length === 0"
                                text
                                small
                                @click="markAllAsRead"
                            >
                                Mark all as read
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-list v-scroll.self="onScroll" style="max-height: 400px; overflow-y: auto">
                        <!-- Individual notifications -->
                        <v-list-item
                            v-for="notification in filteredNotifications"
                            :key="notification.id"
                            href="#"
                            tag="inertia-link"
                            @click="handleNotificationClick(notification)"
                        >
                            <!-- Spinner or unread icon -->
                            <v-list-item-avatar size="8">
                                <v-progress-circular v-if="markingNotification === notification.id" indeterminate />
                                <v-icon v-else-if="!notification.read_at" color="primary"> mdi-circle-medium </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ notification.data.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ notification.data.description }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <!-- If an avatar is needed -->
                            <v-list-item-icon v-if="notification.data.show_avatar">
                                <scorsync-avatar
                                    :person="notification.data.avatar_person"
                                    :type="notification.data.avatar_type"
                                />
                            </v-list-item-icon>

                            <!-- Actions menu (3-dot / overflow) for toggling read/unread -->
                            <v-list-item-icon>
                                <v-menu offset-y close-on-content-click>
                                    <template #activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click.stop>
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <!-- "Mark as unread" if it's read; "Mark as read" if it's unread -->
                                        <v-list-item
                                            v-if="notification.read_at"
                                            @click.stop="mark(notification, false)"
                                        >
                                            <v-list-item-title>Mark as Unread</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item v-else @click.stop="mark(notification, true)">
                                            <v-list-item-title>Mark as Read</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click.stop="remove(notification)">
                                            <v-list-item-title>Remove</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item-icon>
                        </v-list-item>

                        <!-- Empty State -->
                        <v-list-item v-if="filteredNotifications.length === 0" class="empty-state">
                            <v-list-item-content class="text-center">
                                <v-icon size="40" class="mb-2" color="grey">mdi-bell-outline</v-icon>
                                <v-list-item-title class="grey--text">
                                    <span v-if="viewing === 'All'">No notifications yet...</span>
                                    <span v-else-if="viewing === 'Unread'">No unread notifications...</span>
                                    <span v-else>No read notifications...</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- Show spinner or a small indicator if we are loading more -->
                        <v-list-item v-if="isLoadingMore" disabled>
                            <v-list-item-avatar>
                                <v-progress-circular indeterminate size="20" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>Loading more...</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-container>
            </v-card>
        </v-menu>

        <!-- Snackbar for Real-time Notifications -->
        <v-snackbar v-model="showSnackbar" :timeout="10000" app text top>
            <v-icon left small>mdi-bell</v-icon>
            {{ newNotification?.data?.title }}

            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" color="info" text small @click="menuOpen = true">View</v-btn>
                <v-btn v-bind="attrs" text small @click="showSnackbar = false">Dismiss</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import DatabaseNotification from '@/Models/DatabaseNotification.js';
import ScorsyncAvatar from '@/Components/ScorsyncAvatar.vue';
import { Scroll } from 'vuetify/lib/directives';

Vue.directive('scroll', Scroll);

export default {
    name: 'NotificationDropdown',
    components: { ScorsyncAvatar },

    data() {
        return {
            menuOpen: false,
            unreadCount: 0,

            errorMarkingNotification: false,
            markingNotification: null,
            markingAll: false,

            paginator: null,
            isLoadingMore: false,
            viewing: 'All',

            showSnackbar: false,
            newNotification: null,
        };
    },

    computed: {
        filteredNotifications() {
            if (this.viewing === 'All') {
                return this.notifications;
            }

            return this.notifications.filter((n) => {
                return this.viewing === 'Unread' ? !n.read_at : n.read_at;
            });
        },

        notifications() {
            // All notifications from the local store
            return DatabaseNotification.query()
                .where('notifiable_type', 'App\\Models\\User')
                .where('notifiable_id', this.$page.props.auth?.user?.id)
                .orderBy('created_at', 'desc')
                .get();
        },

        unreadNotifications() {
            return this.notifications.filter((notification) => !notification.read_at);
        },
    },

    created() {
        this.unreadCount = this.$page.props.unread_notifications_count || 0;

        // 1) Insert initial notifications delivered by Inertia into local store
        if (this.$page.props.notifications?.data) {
            DatabaseNotification.insert({ data: this.$page.props.notifications.data });
        }

        // 2) Capture the "next page" URL from Laravel’s paginator
        if (this.$page.props.notifications) {
            this.paginator = this.$page.props.notifications;
        }

        this.startListeningForNotifications();
    },

    beforeDestroy() {
        this.$echo.leave(`App.Models.User.${this.$page.props.auth?.user?.id}`);

        console.log('No longer listening for notifications...');
    },

    methods: {
        handleNotificationClick(notification) {
            if (!notification.read_at) {
                this.mark(notification, true);
            }

            if (notification.data.action?.url) {
                this.$inertia.visit(notification.data.action?.url);
            }

            this.menuOpen = false;
        },

        /**
         * Marks a single notification as read or unread.
         * @param {Object} notification
         * @param {Boolean} read
         */
        async mark(notification, read = true) {
            this.markingNotification = notification.id;

            try {
                await axios.put('/api/notifications/mark', {
                    notifications: [{ id: notification.id, read }],
                });

                notification.$update({ read_at: read ? new Date() : null });

                this.unreadCount += read ? -1 : 1;
            } catch (error) {
                this.errorMarkingNotification = true;
            } finally {
                this.markingNotification = null;
            }
        },

        /**
         * Marks all unread notifications as read in one request.
         */
        async markAllAsRead() {
            this.markingAll = true;

            try {
                const unread = this.unreadNotifications;
                await axios.put('/api/notifications/mark', { all: true });

                // Optimistically update all unread locally
                unread.forEach((notification) => {
                    notification.$update({ read_at: new Date() });
                });

                this.unreadCount = 0;
            } catch (error) {
                this.errorMarkingNotification = true;
            } finally {
                this.markingAll = false;
            }
        },

        remove(notification) {
            DatabaseNotification.api().delete(`/api/notifications/${notification.id}`, { delete: notification.id });

            if (!notification.read_at) {
                this.unreadCount -= 1;
            }
        },

        /**
         * Triggered by scrolling in the notification list.
         * Checks if we should load more notifications.
         */
        onScroll(event) {
            // This will be the native DOM element, not a Vue component instance
            const container = event.target;
            if (!container) return;

            const threshold = 50; // Adjust as needed
            const scrollPosition = container.scrollTop + container.clientHeight;
            const scrollHeight = container.scrollHeight;

            // If near the bottom, load more
            if (scrollPosition + threshold >= scrollHeight) {
                this.loadMoreNotifications();
            }
        },

        /**
         * Fetches more notifications from the server and inserts them locally.
         */
        async loadMoreNotifications() {
            if (!this.paginator?.current_page || this.isLoadingMore) return;
            if (this.paginator.current_page >= this.paginator.last_page) return;

            this.isLoadingMore = true;

            try {
                const response = await axios.get(`/api/notifications?page=${this.paginator.current_page + 1}`);

                if (response.data?.data) {
                    await DatabaseNotification.insert({ data: response.data.data });
                }

                // Update the paginator
                this.paginator = response.data;
            } catch (error) {
                console.error('Error loading more notifications:', error);
            } finally {
                this.isLoadingMore = false;
            }
        },

        startListeningForNotifications() {
            if (!this.$page.props.auth?.user?.id) return;

            console.log('Listening for notifications...');

            // Listen for new notifications from Laravel Echo
            this.$echo
                .private(`App.Models.User.${this.$page.props.auth?.user?.id}`)
                .notification(async (notificationEvent) => {
                    await DatabaseNotification.api().get(`/api/notifications/${notificationEvent.id}`);

                    const notification = DatabaseNotification.find(notificationEvent.id);

                    if (!notification.read_at) {
                        this.unreadCount += 1;

                        // Show snackbar with the new notification
                        this.newNotification = notification;
                        this.showSnackbar = true;
                    }
                });
        },
    },
};
</script>

<style>
.empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust if needed */
}
</style>

<template>
    <div v-show="showing" class="pulsating-circle" :style="`background: ${color}`" v-bind="$attrs"></div>
</template>

<script>
export default {
    name: 'LiveIndicator',

    props: {
        color: {
            type: String,
            default: '#E53935',
        },

        timeout: {
            type: Number,
            required: false,
            default: null,
        },
    },

    data: () => ({
        showing: true,
    }),

    created() {
        if (this.timeout) {
            setTimeout(() => {
                this.showing = false;
            }, this.timeout * 1000);
        }
    },
};
</script>

<style>
.pulsating-circle {
    border-radius: 50%;
    margin: 10px 10px 10px 10px;
    height: 15px;
    width: 15px;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(100, 100, 100, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
</style>

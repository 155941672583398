<template>
    <div>
        <slot name="modal-activator" :open="open">
            <div class="text-center mt-8">
                <v-btn color="white" small light @click.stop="open">
                    <v-icon left>mdi-account-box</v-icon>
                    <slot name="change-text">Change Photo</slot>
                </v-btn>
            </div>
        </slot>

        <v-dialog v-model="modal" width="500" @click:outside="$emit('close')">
            <v-card max-width="520" raised="raised">
                <v-card-title>
                    <v-icon left="left">mdi-account-box</v-icon>
                    <span>Change Photo</span>
                </v-card-title>

                <v-card-text>
                    <p v-if="model === 'athlete'">
                        Please submit headshots where the face is clearly visible and right side up.
                    </p>

                    <image-editor
                        :initial="current"
                        circular
                        @update="(image) => (editedImage = image)"
                        @force-remove="(value) => (forceRemove = value)"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close"> Cancel </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="!editedImage && !forceRemove"
                        :loading="submitting"
                        @click="submit"
                    >
                        <slot name="submit-text">Submit</slot>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import numeral from 'numeral';
import Athlete from '@/Models/Athlete.js';
import ImageEditor from '@/Components/ImageEditor.vue';

export default {
    name: 'AvatarChanger',

    components: { ImageEditor },

    props: {
        model: {
            type: String,
            required: false,
            default: 'athlete',
        },

        current: {
            required: false,
            default: '',
            validator: (value) => typeof value === 'string' || !value,
        },

        entityId: {
            type: Number,
            required: true,
        },

        noSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },

        aspectRatio: {
            type: Number,
            required: false,
            default: 1,
        },
    },

    data() {
        return {
            modal: false,
            submitting: false,

            editedImage: null,
            forceRemove: false,
        };
    },

    computed: {
        placeholder() {
            return this.current ? 'Change photo' : 'Select a photo';
        },
    },

    methods: {
        numeral,

        submit() {
            const submitter = async (buffer) => {
                const formData = new FormData();

                if (buffer) {
                    const blob = new Blob([buffer], { type: this.editedImage.type });
                    formData.append('avatar', blob, 'avatar.png');

                    if (this.noSubmit) {
                        this.$emit('input', blob);
                    }
                } else {
                    formData.append('avatar', null);
                    this.$emit('input', null);
                }

                if (this.noSubmit) {
                    this.modal = false;

                    return;
                }

                formData.append('_method', 'put');
                formData.append('model', this.model);

                if (this.forceRemove) {
                    formData.append('force_remove', 'true');
                }

                this.submitting = true;

                await this.$inertia.post(`/avatar/${this.entityId}`, formData, { forceFormData: true });

                this.submitting = false;

                this.modal = false;
            };

            if (this.forceRemove) {
                submitter(null);
            } else {
                this.editedImage.arrayBuffer().then((buffer) => {
                    submitter(buffer);
                });
            }
        },

        async removePhoto() {
            await this.$inertia.post(`/avatar/${this.entityId}`, { _method: 'delete', model: this.model });

            if (this.model === 'athlete') {
                await Athlete.find(this.entityId).$update({ profile_photo_path: null, profile_photo_url: null });
            }

            this.modal = false;
        },

        open() {
            this.modal = true;
        },

        close() {
            this.modal = false;
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.image-container {
    display: inline-block;
}

.image-preview {
    display: block;
    max-height: 229px;
    max-width: 100%;
}
</style>

<style lang="scss" scoped>
.clickable {
    ::v-deep input {
        cursor: pointer;
    }
}
</style>

<template>
    <v-card>
        <v-card-title> Move {{ event }} Video </v-card-title>
        <v-card-text>
            <athlete-selector v-model="selectedAthlete" />

            <v-select v-model="selectedRound" label="Round" :items="rounds" prepend-icon="mdi-trophy" />

            <v-select v-model="selectedNum" label="Routine Number" :items="[1, 2]" prepend-icon="mdi-pound" />
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn text @click="$emit('close')"> Cancel </v-btn>
            <v-btn :loading="saving" text @click="save"> Move </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Video from '@/Models/Video.js';
import AthleteSelector from '@/Components/AthleteSelector.vue';

export default {
    name: 'MoveVideo',

    components: { AthleteSelector },

    props: {
        video: {
            type: Video,
            required: true,
        },

        meetId: {
            type: Number,
            required: true,
        },

        saving: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        selectedAthlete: null,
        selectedRound: 'prelims',
        selectedNum: 1,

        rounds: [
            { value: 'prelims', text: 'Qualification' },
            { value: 'semi_final', text: 'Q2' },
            { value: 'finals', text: 'Finals' },
            { value: 'final_one', text: 'Final One' },
            { value: 'final_two', text: 'Final Two' },
        ],
    }),

    computed: {
        event() {
            return {
                'App\\Models\\TrampolineScore': 'Trampoline',
                'App\\Models\\DoubleMiniScore': 'Double-Mini',
                'App\\Models\\TumbliingScore': 'Tumbliing',
                'App\\Models\\SynchroScore': 'Synchro',
            }[this.video.routine_type];
        },
    },

    methods: {
        save() {
            this.$emit('saved', {
                athlete: this.selectedAthlete,
                round: this.selectedRound,
                num: this.selectedNum,
            });
        },
    },
};
</script>

<style scoped lang="scss"></style>

<template>
    <div>
        <file-pond
            ref="pond"
            name="file"
            :files="initialImage"
            :server="server"
            v-bind="additionalOptions"
            label-idle="Drop an image here or browse..."
            accepted-file-types="image/jpeg, image/png, image/gif"
            image-preview-height="170"
            allow-remove="false"
            :allow-multiple="false"
            @addfile="handleAddFile"
        />

        <slot
            name="controls"
            :edited-image="editedImage"
            :open-pintura-editor="openImageEditor"
            :remove-image-dialog="removeImageDialog"
        >
            <div class="text-center">
                <v-btn v-if="editedImage" class="mr-2" x-small outlined @click="openImageEditor">
                    <v-icon size="small">mdi-pencil</v-icon>
                    Edit
                </v-btn>

                <v-btn v-if="editedImage" x-small outlined @click="removeImageDialog = true">
                    <v-icon size="small">mdi-delete</v-icon>
                    Remove
                </v-btn>
            </div>
        </slot>

        <v-dialog v-model="removeImageDialog" max-width="290">
            <v-card>
                <v-card-title class="headline">Are you sure?</v-card-title>
                <v-card-text>
                    <template v-if="initial && hasEditedImage">
                        Are you sure you want to remove the image?
                        <v-checkbox v-model="removeImageConfirmation" label="Also remove existing image" />
                    </template>
                    <template v-else> Are you sure you want to remove the image? </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="removeImageDialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="removeImage">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { openDefaultEditor } from '@pqina/pintura';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import '@pqina/pintura/pintura.css';

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFilePoster,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
);
export default {
    name: 'ImageEditor',

    components: { FilePond },

    props: {
        initial: {
            type: String,
            required: false,
            default: null,
        },

        circular: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    emits: ['update', 'force-remove'],

    data: () => ({
        removeImageDialog: false,
        removeImageConfirmation: false,
        objectUrl: null,
        editor: null,
        editedImage: null,
        hasEditedImage: null,
        initialImage: [],
        additionalOptions: {},
        server: {
            load: (source, load) => {
                const myRequest = new Request(source);

                fetch(myRequest).then(function fetchRequest(response) {
                    response.blob().then(function loadBlob(myBlob) {
                        load(myBlob);
                    });
                });
            },
        },
    }),

    watch: {
        editedImage(editedImage) {
            this.$emit('update', editedImage);

            if (editedImage) {
                this.$emit('force-remove', false);
            }
        },
    },

    created() {
        if (this.initial) {
            this.initialImage.push({
                source: this.initial,
                options: {
                    type: 'local',
                },
            });
        }

        if (this.circular) {
            this.additionalOptions = {
                stylePanelLayout: 'compact circle',
                styleLoadIndicatorPosition: 'center bottom',
                styleProgressIndicatorPosition: 'right bottom',
                styleButtonRemoveItemPosition: 'left bottom',
                styleButtonProcessItemPosition: 'right bottom',
            };
        }
    },

    methods: {
        handleAddFile() {
            const files = this.$refs.pond.getFiles();

            if (files.length > 0) {
                this.editedImage = files[0].file;

                if (this.hasEditedImage === null) {
                    this.hasEditedImage = false;
                } else if (this.hasEditedImage === false) {
                    this.hasEditedImage = true;
                }
            }
        },

        openImageEditor() {
            // noinspection JSVoidFunctionReturnValueUsed
            this.editor = openDefaultEditor({
                src: URL.createObjectURL(this.editedImage),
                utils: ['crop', 'filter', 'finetune', 'frame', 'redact', 'resize'],
            }).on('process', ({ dest }) => {
                const image = new File([dest], this.editedImage.name, {
                    type: dest.type,
                    lastModified: new Date().getTime(),
                });

                this.$refs.pond.removeFile();
                this.$refs.pond.addFile(image);

                this.editedImage = image;
            });
        },

        removeImage() {
            if (this.editedImage) {
                this.$refs.pond.removeFile();
                this.editedImage = null;
            }

            if (this.removeImageConfirmation || this.hasEditedImage === false) {
                this.$emit('force-remove', true);
            }

            this.removeImageDialog = false;
        },
    },
};
</script>

<style scoped>
.filepond--credits {
    display: none;
}
</style>

<template>
    <div>
        <video-player v-if="loaded" :options="videoOptions" />

        <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="10000" :top="true">
            {{ snackbar.text }}
            <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import find from 'lodash/find.js';
import isEmpty from 'lodash/isEmpty.js';
import VideoPlayer from '@/Components/VideoPlayer.vue';

export default {
    name: 'RoutineVideo',

    components: {
        VideoPlayer,
    },

    props: {
        video: { type: Object, required: true },
        autoplay: { type: Boolean, default: false },
        controls: { type: Boolean, default: true },
        playbackRates: { type: Array, default: () => [0.25, 0.5, 1, 1.5, 2] },
        loop: { type: Boolean, default: true },
    },

    data: () => ({
        rawPath: null,
        isMobile: false,

        snackbar: {
            show: false,
            text: '',
            color: 'error',
        },
    }),

    computed: {
        loaded() {
            if (!this.video) {
                return false;
            }

            if (!this.video.prefer_hls && !this.rawPath) {
                return false;
            }

            return !isEmpty(this.videoOptions);
        },

        videoOptions() {
            if (!this.isVideoReady()) return {};

            const sources = this.generateSources();

            return {
                autoplay: this.autoplay,
                controls: this.controls,
                playbackRates: this.playbackRates,
                sources,
                aspectRatio: '16:9',
                loop: this.loop,
                preload: 'auto',
                responsive: true,
                fluid: true,
            };
        },
    },

    created() {
        this.checkMobileDevice();
        if (this.video && !this.video.prefer_hls) {
            this.retrieveSignedUrl();
        }
    },

    methods: {
        checkMobileDevice() {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

        isVideoReady() {
            return this.video && (this.video.prefer_hls || this.rawPath);
        },

        async retrieveSignedUrl() {
            try {
                const response = await axios.get(`/api/videos/${this.video.id}/signed-url`);
                this.rawPath = response.data.signed_url;
            } catch (error) {
                console.error('Error fetching signed URL:', error);
                this.snackbar.text = 'Error fetching video. Please try again.';
                this.snackbar.show = true;
            }
        },

        generateSources() {
            const sources = [];

            if (typeof this.video === 'string') {
                sources.push({ src: this.video, type: 'video/mp4' });
            } else if (this.video.prefer_hls) {
                const httpStream = find(this.video.output, { key: 'httpstream' });
                const mp4Stream = find(this.video.output, { key: 'mp4:1080p' });
                const mp4MediumStream = find(this.video.output, { key: 'mp4:720p' });
                const mp4LowStream = find(this.video.output, { key: 'mp4:480p' });

                const hls = find(httpStream?.urls, { format: 'hls' });

                if (this.isIOS() && hls) {
                    sources.push({ src: hls.url, type: 'application/x-mpegURL' });
                } else {
                    if (mp4LowStream && this.isMobile) sources.push({ src: mp4LowStream.url, type: 'video/mp4' });
                    if (mp4MediumStream && this.isMobile) sources.push({ src: mp4MediumStream.url, type: 'video/mp4' });
                    if (mp4Stream) sources.push({ src: mp4Stream.url, type: 'video/mp4' });

                    if (hls) sources.push({ src: hls.url, type: 'application/x-mpegURL' });
                }
            } else if (this.rawPath) {
                sources.push({ src: this.rawPath, type: 'video/mp4' });
            }

            return sources;
        },

        isIOS() {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        },
    },
};
</script>

/* eslint-disable import/prefer-default-export */

/**
 * Imports the given page component from the page record.
 */
export function resolvePageComponent(name, pages) {
    if (!name) {
        throw new Error('No page name was provided.');
    }

    const pageName = `${name.replace('.', '/')}.vue`;

    const foundPath = Object.keys(pages).find((path) => path.replace('../Pages/', '') === pageName);

    if (foundPath) {
        const page = typeof pages[foundPath] === 'function' ? pages[foundPath]() : pages[foundPath];

        // page.layout = AppLayout;

        return page;
    }

    throw new Error(`Page not found: ${name}`);
}

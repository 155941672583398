import Model from '@/Models/Model.js';

export default class DatabaseNotification extends Model {
    static entity = 'database_notifications';

    static modelName = 'DatabaseNotification';

    static fields() {
        return {
            id: this.attr(null),

            type: this.attr(null),
            notifiable_type: this.attr(null),
            notifiable_id: this.attr(null),
            data: this.attr(null),
            read_at: this.attr(true),

            ...super.fields(),
        };
    }

    async markAsRead() {

    }
}

import { createInertiaApp, router } from '@inertiajs/vue2';
import Vue from 'vue';
import { resolvePageComponent } from '@/Bootstrap/utils.js';
import vuetify from '@/Plugins/vuetify.js';

import store from '@/Bootstrap/store.js';
import AppLayout from '@/Layouts/AppLayout.vue';

createInertiaApp({
    resolve: async (name) => {
        const pageComponent = await resolvePageComponent(name, import.meta.glob('./../Pages/**/*.vue'));

        if (pageComponent.default.layout !== 'ignore') {
            pageComponent.default.layout = pageComponent.default.layout || AppLayout;
        }

        return pageComponent;
    },

    progress: {
        color: '#29d',
    },

    setup({ el, App, props, plugin }) {
        Vue.use(plugin);

        // Vue.use(DatetimePicker);

        window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        window.axios.defaults.withCredentials = true;

        router.on('before', (event) => {
            if (store.state.forceHardNavigation) {
                event.preventDefault();
                window.location = event.detail.visit.url.href;
            }
        });

        new Vue({
            render: (h) => h(App, props),
            store,
            vuetify,
        }).$mount(el);
    },
});

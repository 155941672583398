<template>
    <div>
        <v-tooltip v-if="useTooltip" v-bind="tooltipProps">
            <template #activator="{ on, attrs }">
                <v-btn v-bind="{ ...attrs, ...buttonProps }" v-on="{ ...on, ...$listeners }">
                    <slot>Button</slot>
                </v-btn>
            </template>
            <slot name="tooltipText">
                <span>{{ tooltipText }}</span>
            </slot>
        </v-tooltip>
        <v-btn v-else v-bind="buttonProps" v-on="$listeners">
            <slot name="default">Button</slot>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'TooltipButton',

    props: {
        useTooltip: {
            type: Boolean,
            default: true,
        },

        tooltipText: {
            type: String,
            default: '',
        },

        tooltipProps: {
            type: Object,
            default: () => ({}),
        },

        buttonProps: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

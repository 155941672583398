import Model from '@/Models/Model.js';
import Result from '@/Models/Result.js';
import AthleteGoal from '@/Models/AthleteGoal.js';
import Athlete from '@/Models/Athlete.js';

export default class AthleteGoalAchievement extends Model {
    static entity = 'athlete_goal_achievements';

    static modelName = 'AthleteGoalAchievement';

    static fields() {
        return {
            id: this.attr(null),
            athlete_id: this.attr(null),
            athlete_goal_id: this.attr(null),
            result_id: this.attr(null),
            routineable_id: this.attr(null),
            routineable_type: this.attr(null),

            athlete: this.belongsTo(Athlete, 'athlete_id'),
            athlete_goal: this.belongsTo(AthleteGoal, 'athlete_goal_id'),
            result: this.belongsTo(Result, 'result_id'),
            routineable: this.morphTo('routineable_id', 'routineable_type'),

            ...super.fields(),
        };
    }
}

import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';
import Athlete from '@/Models/Athlete.js';
import Club from '@/Models/Club.js';
import ResultCategory from '@/Models/ResultCategory.js';
import Result from '@/Models/Result.js';

export default class AllAroundResult extends Model {
    static entity = 'all_around_results';

    static modelName = 'AllAroundResult';

    static fields() {
        return {
            id: this.attr(null),

            meet_id: this.attr(null),
            athlete_id: this.attr(null),
            club_id: this.attr(null),
            category_id: this.attr(null),
            tra_result_id: this.attr(null),
            dmt_result_id: this.attr(null),
            tum_result_id: this.attr(null),
            level: this.attr(null),
            gender: this.attr(null),
            age: this.attr(null),
            age_group: this.attr(null),
            prelims_score: this.attr(null),
            prelims_placement: this.attr(null),
            prelims_rank: this.attr(null),

            meet: this.belongsTo(Meet, 'meet_id'),
            athlete: this.belongsTo(Athlete, 'athlete_id'),
            club: this.belongsTo(Club, 'club_id'),
            category: this.belongsTo(ResultCategory, 'category_id'),
            tra_result: this.belongsTo(Result, 'tra_result_id'),
            dmt_result: this.belongsTo(Result, 'dmt_result_id'),
            tum_result: this.belongsTo(Result, 'tum_result_id'),

            ...super.fields(),
        };
    }

    get routine_results() {
        return [];
    }

    get latest_result() {
        return _.first(
            _.sortBy(
                [this.tra_result, this.dmt_result, this.tum_result].filter((result) => result !== null),
                ['updated_at', 'desc'],
            ),
        );
    }

    changed() {
        return false;
    }

    routineResultsForRound() {
        return [];
    }

    ordinalRank(field) {
        if (this[field] === undefined) {
            return '';
        }

        if (this[field] === null) {
            return 'N/A';
        }

        console.log(this, field, 'asdfa');

        if (this[field].includes('T')) {
            return this[field];
        }

        const n = parseInt(this[field], 10);

        // eslint-disable-next-line
        return `${n}${[, 'st', 'nd', 'rd'][(n / 10) % 10 ^ 1 && n % 10] || 'th'}`;
    }
}

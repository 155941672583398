import { kebabCase } from 'lodash';
import Model from '@/Models/Model.js';
import Session from '@/Models/Session.js';
import TeamQualifier from '@/Models/TeamQualifier.js';
import MeetTeamQualifier from '@/Models/MeetTeamQualifier.js';
import Result from '@/Models/Result.js';
import Coupon from '@/Models/Coupon.js';
import TeamDefinitionSetting from '@/Models/TeamDefinitionSetting.js';
import Media from '@/Models/Media.js';
import Announcement from '@/Models/Announcement.js';

export default class Meet extends Model {
    static entity = 'meets';

    static fields() {
        return {
            id: this.attr(null),
            title: this.string(null),
            title_with_year: this.string(null),
            information: this.attr(null),
            slug: this.string(null),
            host_id: this.attr(null),
            logo: this.attr(null),
            large_logo: this.attr(null),
            block_schedule: this.attr(null),
            block_schedule_url: this.attr(null),
            training_schedule: this.attr(null),
            training_schedule_url: this.attr(null),
            team_format: this.attr(null),
            break_jo_ties_prelims: this.boolean(false),
            break_jo_ties_finals: this.boolean(false),
            break_elite_ties_prelims: this.boolean(false),
            break_elite_ties_finals: this.boolean(false),
            jo_qualification_meet: this.boolean(false),
            elite_qualification_meet: this.boolean(false),
            elite_mobility: this.boolean(false),
            jo_new_life_finals: this.boolean(false),
            elite_new_life_finals: this.boolean(false),
            exclude_foreign_athletes: this.boolean(false),
            has_team_results: this.boolean(false),
            start_date: this.attr(null),
            end_date: this.attr(null),
            starts_at: this.attr(null),
            ends_at: this.attr(null),
            is_live: this.attr(null),
            timezone: this.attr(null),
            location: this.string(null),
            token: this.attr(null),
            live_until: this.attr(null),
            visible_at: this.attr(null),
            hidden_at: this.attr(null),
            is_international: this.boolean(false),
            is_free_to_access: this.boolean(false),
            has_extra_awards: this.boolean(false),
            force_traditional_age_groups: this.boolean(false),
            all_around: this.boolean(false),
            created_at: this.string(null),
            updated_at: this.string(null),
            created_by: this.attr(null),
            updated_by: this.attr(null),
            team_definition_settings_count: this.attr(null),
            is_a_one_day_meet: this.boolean(false),

            sessions: this.hasMany(Session, 'meet_id'),
            team_qualifiers: this.belongsToMany(TeamQualifier, MeetTeamQualifier, 'meet_id', 'team_qualifier_id'),
            results: this.hasMany(Result, 'meet_id'),
            coupons: this.hasMany(Coupon, 'meet_id'),
            team_definition_settings: this.hasMany(TeamDefinitionSetting, 'meet_id'),
            media: this.morphMany(Media, 'model_id', 'model_type_entity'),
            announcements: this.hasMany(Announcement, 'meet_id'),
        };
    }

    static state() {
        return {
            verifyingResults: false,
        };
    }

    get date_range() {
        if (!this.start_date) {
            return null;
        }

        const start = this.timestamp('start_date').tz('UTC');

        if (!this.end_date || start.isSame(this.timestamp('end_date').tz('UTC'), 'day')) {
            return start.format('MMMM D, YYYY');
        }

        const end = this.timestamp('end_date').tz('UTC');

        if (start.month() === end.month()) {
            return `${start.format('MMMM D')} - ${end.format('D, YYYY')}`;
        }
        return `${start.format('MMMM D')} - ${end.format('MMMM D, YYYY')}`;
    }

    get year() {
        return this.timestamp('start_date').format('YYYY');
    }

    get ranking_key() {
        return `${this.timestamp('start_date').format('YYYY')}-${kebabCase(this.title)}`;
    }

    get qualification_meet() {
        return this.jo_qualification_meet || this.elite_qualification_meet;
    }
}

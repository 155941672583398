<!--suppress RequiredAttributes -->
<template>
    <div>
        <video
            ref="videoPlayer"
            class="video-js"
            data-setup='{"fluid": true, "html5": {"hls": {"overrideNative": true}}}'
            crossOrigin="use-credentials"
            playsinline
            webkit-playsinline
            muted
        >
            <track kind="captions" />
        </video>
    </div>
</template>

<script>
import LoadExternalScriptsMixin from '@/Mixins/LoadExternalScripts.mixin.js';

export default {
    name: 'VideoPlayer',

    mixins: [LoadExternalScriptsMixin],

    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
    },

    data: () => ({
        player: null,
        playerLoaded: false,
    }),

    mounted() {
        if (!window.videojs) {
            this.loadStylesheet('https://vjs.zencdn.net/7.20.1/video-js.css');
            // Load videojs-contrib-hls for better HLS support on all platforms
            this.loadScript('https://vjs.zencdn.net/7.20.1/video.min.js', 'video-player', () => {
                this.loadScript(
                    'https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-hls/5.15.0/videojs-contrib-hls.min.js',
                    'videojs-hls',
                    () => {
                        this.loadPlayer();
                    },
                );
            });
        } else {
            this.loadPlayer();
        }
    },

    beforeDestroy() {
        if (this.player) {
            this.player.pause();
            this.player.dispose();
        }
    },

    methods: {
        loadPlayer() {
            this.playerLoaded = true;
            this.$nextTick(() => {
                if (window.videojs) {
                    const playerOptions = {
                        ...this.options,
                        techOrder: ['html5'],
                        html5: {
                            hls: { overrideNative: true },
                            nativeVideoTracks: false,
                            nativeAudioTracks: false,
                            nativeTextTracks: false,
                        },
                    };

                    this.player = window.videojs(this.$refs.videoPlayer, playerOptions);

                    // Handle autoplay issues on mobile
                    if (this.options.autoplay && this.isMobileDevice()) {
                        this.player.muted(true); // Muting helps bypass autoplay restrictions
                        this.player.on('loadedmetadata', () => {
                            const playPromise = this.player.play();
                            if (playPromise !== undefined) {
                                playPromise.catch((error) => {
                                    // Auto-play was prevented, add a play button or notification
                                    console.log('Autoplay prevented:', error);
                                    // Unmute if autoplay was blocked to allow user control
                                    this.player.muted(false);
                                });
                            }
                        });
                    }
                }
            });
        },

        isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
    },
};
</script>
